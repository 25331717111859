import React, { FunctionComponent } from "react"
import type { HeadFC, PageProps } from "gatsby"
import "../styles.scss"

const IndexPage: FunctionComponent<PageProps> = (data: PageProps) => {
  const today = new Date().getFullYear();

  return (
    <section className="hero is-black is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title header-title">
            <span className="is-purple">F</span>
            <span className="is-pink">K</span>
            <span className="is-purple">P</span>
          </h1>
          <p className="subtitle">
            Our goal at Fresh KPop is to create a global community that delivers high quality content about YOUR most loved biases, bands, and idols. 
          </p>
          <p className="subtitle">
            However, to best serve you, we need a little bit of patience as we prepare our community hub. If YOU are interested in sharing the freshest KPop updates with your friends, stay tuned!           </p>
          <p className="subtitle">
            We are training hard and are focused on delivering a strong debut.           
          </p>
          <p className="subtitle">
            Hwaiting!
          </p>
          <hr className="line"/>
        </div>
      </div>
      <div className="hero-foot">
        <div className="container has-text-centered">
          <p>© Copyright <time dateTime={`${today}-01-01`}>{today}</time></p>
        </div>
      </div>
    </section>
  )
}

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <html lang="en" />
    <title>Fresh KPop</title>
    <meta name="description" content="A global KPop community with the latest updates" />
  </>
)